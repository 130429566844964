"use client";

import Link from "next/link";
import styles from "./header-nav.module.scss";
import { FC, useEffect, useState } from "react";
import { CartIcon, CatalogIcon, MainIcon, MenuIcon } from "@/components/icon";
import { PersonalIcon } from "@/components/icon/personal-icon/personal-icon";
import { useAppSelector } from "@/services/redux/hooks";
import { getAuthStatus, getUserInfo } from "@/services/redux/features/userSlice";
import { useRouter } from "next/navigation";
import { useOpenModal } from "@/services/hooks/useOpenModal";
import { AuthPopup, GeoPopup } from "@/components/popups";
import NewCatalogModal from "@/components/CatalogModal";
interface IHeaderNav {
  setIsOpenMenu: (value: boolean) => void;
  catalog: any;
}
export const HeaderNav: FC<IHeaderNav> = ({
  setIsOpenMenu,
  catalog
}) => {
  const router = useRouter();
  const cart = useAppSelector(getUserInfo).info.cart;
  const countCart = cart.filter(item => !item.isRemoved).length;
  const [isClient, setIsClient] = useState(false);
  const userName = useAppSelector(getUserInfo).info.name;
  const isAuthUser = useAppSelector(getAuthStatus).isAuth;
  const [showModal, handleOpenModal, handleCloseModal] = useOpenModal();
  const [showModalGeo, handleOpenModalGeo, handleCloseModalGeo] = useOpenModal();
  const [showModalCategories, handleOpenModalCategories, handleCloseModalCategories] = useOpenModal();
  useEffect(() => {
    setIsClient(true);
  }, []);
  const handleClick = () => isAuthUser ? router.push("/cabinet/") : handleOpenModal();
  const displayText = isClient ? isAuthUser ? userName ? userName : "Профиль" : "Войти" : "Войти";
  const closeCatalog = () => {
    document.body.style.overflow = "auto";
    handleCloseModalCategories();
  };
  const openCatalog = () => {
    document.body.style.overflow = "hidden";
    handleOpenModalCategories();
  };
  return <>
      <div className={styles.wrap}>
        {showModalGeo ? <GeoPopup isOpen={showModalGeo} onClose={handleCloseModalGeo} /> : null}
        {showModalCategories ? <NewCatalogModal visible={showModalCategories} handleClose={closeCatalog} catalog={catalog} /> : null}
        {showModal ? <AuthPopup isOpen={showModal} onClose={handleCloseModal} /> : null}
        <div className={styles.modal_container}></div>
        <ul className={styles.list}>
          <li className={styles.item}>
            <Link prefetch={false} href="/" className={styles.button} title="Главная" data-sentry-element="Link" data-sentry-source-file="header-nav.tsx">
              <MainIcon data-sentry-element="MainIcon" data-sentry-source-file="header-nav.tsx" />
              Главная
            </Link>
          </li>
          <li className={styles.item}>
            <button onClick={() => setIsOpenMenu(true)} className={styles.button}>
              <MenuIcon data-sentry-element="MenuIcon" data-sentry-source-file="header-nav.tsx" />
              Меню
            </button>
          </li>
          <li className={styles.itemButton}>
            <button onClick={openCatalog} className={styles.button} title="Каталог">
              <CatalogIcon data-sentry-element="CatalogIcon" data-sentry-source-file="header-nav.tsx" />
              Каталог
            </button>
          </li>
          <li className={styles.item}>
            <Link prefetch={false} href="/cart/" className={styles.button} title="Корзина" data-sentry-element="Link" data-sentry-source-file="header-nav.tsx">
              <CartIcon data-sentry-element="CartIcon" data-sentry-source-file="header-nav.tsx" />
              Корзина
              <span className={styles.count}>{isClient ? countCart : 0}</span>
            </Link>
          </li>
          <li className={styles.item}>
            <button title="Личный кабинет" onClick={handleClick} className={styles.button}>
              <PersonalIcon data-sentry-element="PersonalIcon" data-sentry-source-file="header-nav.tsx" />
              {displayText}
            </button>
          </li>
        </ul>
      </div>
    </>;
};