"use client";

import { FC, useEffect, useState } from "react";
import styles from "./first-time.module.scss";
import ButtonComponent from "@/components/button/button";
import { CloseIcon } from "@/components/icon";
import { useAppDispatch } from "@/services/redux/hooks";
import { createPortal } from "react-dom";
import { setCity, setFirstTime } from "@/services/redux/features/citySlice";
import { getCityRequest } from "@/utils/api/getCity";
import { getCityInfoRequest, getQueryCity } from "@/utils/api/cities";
import { createServerCookie } from "@/app/actions";
import { setCookie } from "@/utils/functions/cookie";
import { setDefaultCity } from "@/utils/functions/setDefaultCity";
import { SkeletonLoader } from "@/components/skeleton-loader/skeleton-loader";
import { isClient } from "@/utils/functions/isClient";
interface IFirstTime {
  onClose: () => void;
  anotherCity: () => void;
}
const FirstTime: FC<IFirstTime> = props => {
  const {
    onClose,
    anotherCity
  } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [gorod, setGorod] = useState<any>("Москва");
  const [cityCode, setCityCode] = useState<string>("moskva");
  const dispatch = useAppDispatch();
  const modalRoot = isClient() ? document.getElementById("modal-definition") : {} as Element;
  useEffect(() => {
    getCityRequest().then(res => {
      if (!!res.city) {
        setGorod(res.city.name);
        setCityCode(res.city.code);
      }
      setTimeout(() => ym(42989679, "reachGoal", "showSetCity"), 2000);
    });
    setIsLoaded(true);
  }, []);
  const cityCorrect = async () => {
    ym(42989679, "reachGoal", "wellDefinedCity");
    dispatch(setFirstTime(false));
    getQueryCity(cityCode).then(res => {
      if (res.city) {
        const city = res.city;
        const plainCity = {
          name: city.name,
          code: city.code
        };
        dispatch(setCity(plainCity));
        const cookie = {
          ...plainCity,
          lat: city.geo_lat,
          lon: city.geo_lon
        };
        createServerCookie("choosedCity", JSON.stringify(cookie)).then(r => {
          setCookie("choosedCity", JSON.stringify(cookie));
          onClose();
        });
      } else {
        dispatch(setCity({
          name: "Москва",
          code: "moskva"
        }));
        setDefaultCity();
        onClose();
      }
    });
  };
  const cityIncorrect = () => {
    anotherCity();
    onClose();
  };
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        if (onClose) {
          onClose();
        }
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  if (!isLoaded) return null;
  return createPortal(<>
      <div className={styles.wrap}>
        <div className={styles.head}>
          <p className={styles.title}>Ваш город {gorod}?</p>
          <button onClick={onClose} title="Закрыть">
            <CloseIcon />
          </button>
        </div>
        <p className={styles.text}>
          Это поможет вам заранее увидеть условия доставки товаров и цены в Вашем городе.
        </p>
        <div className={styles.buttons_wrap}>
          <ButtonComponent onClick={cityCorrect} className={`${styles.button} ${styles.button_correct}`}>
            <>Да, верно</>
          </ButtonComponent>
          <ButtonComponent onClick={cityIncorrect} className={`${styles.button} ${styles.button_incorrect}`}>
            <>Нет, другой</>
          </ButtonComponent>
        </div>
      </div>
    </>, modalRoot!);
};
export default FirstTime;