import { StatusIcon } from "@/components/icon";
import styles from "./status-menu-button.module.scss";
import { FC } from "react";
import { StatusOrderPopup } from "@/components/popups";
import { useOpenModal } from "@/services/hooks/useOpenModal";
interface IStatusMenuButton {}
export const StatusMenuButton: FC<IStatusMenuButton> = () => {
  const [showModal, handleOpenModal, handleCloseModal] = useOpenModal();
  return <>
      {showModal && <StatusOrderPopup isOpen={showModal} onClose={handleCloseModal} />}
      <button onClick={handleOpenModal} className={styles.button}>
        <StatusIcon data-sentry-element="StatusIcon" data-sentry-source-file="status-menu-button.tsx" />
        Статус заказа
      </button>
    </>;
};