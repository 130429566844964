"use client";

import styles from "./header-menu-list.module.scss";
import { FC, useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { CatalogIcon, TelHeaderIcon, WorktimeIcon } from "@/components/icon";
import { GeoMenuButton } from "./geo-menu-button/geo-menu-button";
import { ProfileMenuButton } from "./profile-menu-button/profile-menu-button";
import { CartMenuButton } from "./cart-menu-button/cart-menu-button";
import { StatusMenuButton } from "./status-menu-button/status-menu-button";
import { CallbackMenuButton } from "./callback-menu-button/callback-menu-button";
interface IHeaderMenuList {
  closeMenu: () => void;
}
type Position = {
  top: number;
  left: number;
};
export const HeaderMenuList: FC<IHeaderMenuList> = ({
  closeMenu
}) => {
  const headerRef = useRef(document.getElementsByTagName("header")[0]);
  const [position, setPosition] = useState<Position>({
    top: 0,
    left: 0
  });
  const dropIn = {
    hidden: {
      transform: "translateX(-100%)",
      opacity: 0
    },
    visible: {
      transform: "translateX(0)",
      opacity: 1
    },
    exit: {
      transform: "translateX(-100%)",
      opacity: 0
    }
  };
  const ref = useRef(null);
  useEffect(() => {
    const updatePosition = () => {
      if (headerRef.current) {
        const rect = headerRef.current.getBoundingClientRect();
        setPosition({
          top: rect.top,
          left: rect.left
        });
      }
    };
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => {
      window.removeEventListener("scroll", updatePosition);
    };
  }, []);
  return <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-component="HeaderMenuList" data-sentry-source-file="header-menu-list.tsx">
      <motion.div className={styles.wrap} ref={ref} variants={dropIn} initial="hidden" animate="visible" exit="exit" onClick={e => e.stopPropagation()} style={{
      // top: headerRef.current.offsetHeight + marqueeRef.current.offsetHeight,
      top: position.top + 66
    }} itemScope itemType="https://schema.org/SiteNavigationElement" data-sentry-element="unknown" data-sentry-source-file="header-menu-list.tsx">
        <ul className={styles.list}>
          <li className={styles.item}>
            <GeoMenuButton data-sentry-element="GeoMenuButton" data-sentry-source-file="header-menu-list.tsx" />
          </li>
          <li className={styles.item}>
            <ProfileMenuButton data-sentry-element="ProfileMenuButton" data-sentry-source-file="header-menu-list.tsx" />
          </li>
          <li className={styles.item}>
            <CartMenuButton data-sentry-element="CartMenuButton" data-sentry-source-file="header-menu-list.tsx" />
          </li>
          <li className={styles.item} onClick={closeMenu}>
            <Link prefetch={false} href="/catalog/" className={styles.button} data-sentry-element="Link" data-sentry-source-file="header-menu-list.tsx">
              <CatalogIcon data-sentry-element="CatalogIcon" data-sentry-source-file="header-menu-list.tsx" />
              Каталог
            </Link>
          </li>
          <li className={styles.item}>
            <StatusMenuButton data-sentry-element="StatusMenuButton" data-sentry-source-file="header-menu-list.tsx" />
          </li>
          <li className={styles.item}>
            <CallbackMenuButton data-sentry-element="CallbackMenuButton" data-sentry-source-file="header-menu-list.tsx" />
          </li>
          <li className={styles.item}>
            <Link prefetch={false} href="tel:88007008256" className={styles.button} onClick={() => ym(42989679, "reachGoal", "clickOnTel")} data-sentry-element="Link" data-sentry-source-file="header-menu-list.tsx">
              <TelHeaderIcon data-sentry-element="TelHeaderIcon" data-sentry-source-file="header-menu-list.tsx" />8 800 700-82-56
            </Link>
          </li>
          <li className={styles.item}>
            <p className={styles.button}>
              <WorktimeIcon data-sentry-element="WorktimeIcon" data-sentry-source-file="header-menu-list.tsx" />
              Режим работы 7:00 - 22:00 (по МСК)
            </p>
          </li>
        </ul>
        <ul className={styles.list} itemProp="about" itemScope itemType="https://schema.org/ItemList">
          <li className={styles.item} onClick={closeMenu} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/remington/" className={styles.button} itemProp="url" data-sentry-element="Link" data-sentry-source-file="header-menu-list.tsx">
              Remington
            </Link>
            <meta itemProp="name" content="Remington" data-sentry-element="meta" data-sentry-source-file="header-menu-list.tsx" />
          </li>
          <li className={styles.item} onClick={closeMenu} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/live/" className={styles.button} itemProp="url" data-sentry-element="Link" data-sentry-source-file="header-menu-list.tsx">
              Трансляции
            </Link>
            <meta itemProp="name" content="streams" data-sentry-element="meta" data-sentry-source-file="header-menu-list.tsx" />
          </li>
          <li className={styles.item} onClick={closeMenu} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/sales/" className={styles.button} itemProp="url" data-sentry-element="Link" data-sentry-source-file="header-menu-list.tsx">
              Акции
            </Link>
            <meta itemProp="name" content="Акции" data-sentry-element="meta" data-sentry-source-file="header-menu-list.tsx" />
          </li>
          <li className={styles.item} onClick={closeMenu} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/new/" className={styles.button} itemProp="url" data-sentry-element="Link" data-sentry-source-file="header-menu-list.tsx">
              Новинки
            </Link>
            <meta itemProp="name" content="Новинки" data-sentry-element="meta" data-sentry-source-file="header-menu-list.tsx" />
          </li>
          <li className={styles.item} onClick={closeMenu} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/liquidation/" className={styles.button} itemProp="url" data-sentry-element="Link" data-sentry-source-file="header-menu-list.tsx">
              Ликвидация
            </Link>
            <meta itemProp="name" content="Ликвидация" data-sentry-element="meta" data-sentry-source-file="header-menu-list.tsx" />
          </li>
          <li className={styles.item} onClick={closeMenu} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/shops/" className={styles.button} itemProp="url" data-sentry-element="Link" data-sentry-source-file="header-menu-list.tsx">
              Магазины
            </Link>
            <meta itemProp="name" content="Магазины" data-sentry-element="meta" data-sentry-source-file="header-menu-list.tsx" />
          </li>
          <li className={styles.item} onClick={closeMenu} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/blog/" className={styles.button} itemProp="url" data-sentry-element="Link" data-sentry-source-file="header-menu-list.tsx">
              Блог
            </Link>
            <meta itemProp="name" content="Блог" data-sentry-element="meta" data-sentry-source-file="header-menu-list.tsx" />
          </li>
          <li className={styles.item} onClick={closeMenu} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/opt/" className={styles.button} itemProp="url" data-sentry-element="Link" data-sentry-source-file="header-menu-list.tsx">
              Опт
            </Link>
            <meta itemProp="name" content="Опт" data-sentry-element="meta" data-sentry-source-file="header-menu-list.tsx" />
          </li>
          <li className={styles.item} onClick={closeMenu} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/partners/" className={styles.button} itemProp="url" data-sentry-element="Link" data-sentry-source-file="header-menu-list.tsx">
              Поставщикам
            </Link>
            <meta itemProp="name" content="Поставщикам" data-sentry-element="meta" data-sentry-source-file="header-menu-list.tsx" />
          </li>
        </ul>
      </motion.div>
    </AnimatePresence>;
};