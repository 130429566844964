"use client";

import { ChatIcon } from "@/components/icon";
import styles from "./header-actions-chat.module.scss";
import { FC } from "react";
interface IHeaderActionsChat {}
export const HeaderActionsChat: FC<IHeaderActionsChat> = () => {
  const handleClick = () => {
    document.querySelector(".consultant-icon")?.click();
    setTimeout(() => {
      document.querySelector(".messengers__item_consultant")?.click();
    }, 1);
  };
  return <div className={styles.wrap} data-sentry-component="HeaderActionsChat" data-sentry-source-file="header-actions-chat.tsx">
      <button className={styles.button} onClick={handleClick} title="Чат с консультантом">
        <ChatIcon data-sentry-element="ChatIcon" data-sentry-source-file="header-actions-chat.tsx" />
      </button>
    </div>;
};