"use client";

import { PersonalIcon } from "@/components/icon/personal-icon/personal-icon";
import styles from "./profile-menu-button.module.scss";
import { FC, useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { useOpenModal } from "@/services/hooks/useOpenModal";
import { useAppSelector } from "@/services/redux/hooks";
import { getAuthStatus, getIsNewUser, getUserInfo } from "@/services/redux/features/userSlice";
import { AuthPopup } from "@/components/popups";
interface IProfileMenuButton {}
export const ProfileMenuButton: FC<IProfileMenuButton> = () => {
  const router = useRouter();
  const [showModal, handleOpenModal, handleCloseModal] = useOpenModal();
  const isAuthUser = useAppSelector(getAuthStatus).isAuth;
  const userName = useAppSelector(getUserInfo).info.name;
  const isNewUser = useAppSelector(getIsNewUser);
  const handleClick = () => isAuthUser ? router.push("/cabinet/") : handleOpenModal();
  const [isClient, setIsClient] = useState<boolean>(false);
  useEffect(() => setIsClient(true), []);
  const displayText = isClient ? isAuthUser ? userName ? userName : "Профиль" : "Войти" : "Войти";
  return <>
      {showModal && <AuthPopup isOpen={showModal} onClose={handleCloseModal} />}
      {/* {isNewUser && (
        <WelcomeBonusPopup />
       )} */}
      <button onClick={handleClick} className={styles.button}>
        <PersonalIcon data-sentry-element="PersonalIcon" data-sentry-source-file="profile-menu-button.tsx" />
        {displayText}
      </button>
    </>;
};