"use client";

import HeaderActions from "../header-actions/header-actions";
import HeaderLogo from "../header-logo/header-logo";
import HeaderSearch from "../header-search/header-search";
import styles from "./header-bottom.module.scss";
import React, { FC, useEffect, useRef, useState } from "react";
import { useBodyScrollLock } from "@/services/hooks/useBodyScrollLock";
import { ICatalog } from "@/services/types/types";
import { HeaderMenu } from "../header-menu/header-menu";
import { HeaderNav } from "../header-nav/header-nav";
import HeaderCatalogButton from "../header-catalog-button/header-catalog-button";
import { ArrowSearch } from "@/components/icon";
import { useWindowSize } from "@/services/hooks/useWindowSize";
import HeaderCatalog from "../header-catalog/header-catalog";
import { UseMediaQuery } from "@/utils/functions/useMediaQuery";
import NewCatalogModal from "@/components/CatalogModal";
export const HeaderBottom: FC<ICatalog> = ({
  catalog
}) => {
  const [isOpenCatalog, setIsOpenCatalog] = useState<boolean>(false);
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const [isLocked, setIsLocked] = useBodyScrollLock();
  const isMobile = UseMediaQuery('(max-width: 767px)');
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);
  const headerCatalogRef = useRef<any>(null);
  const headerCatalogButtonRef = useRef<any>(null);
  const headerSearchRef = useRef<HTMLDivElement>(null);
  const [catalogVisible, setCatalogVisible] = useState<boolean>(false);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (headerCatalogRef.current && !headerCatalogRef.current.contains(event.target as Node) && !!headerCatalogButtonRef.current && !headerCatalogButtonRef.current?.contains(event.target as Node)) {
        if (!!headerSearchRef.current && headerSearchRef.current?.contains(event.target as Node)) {
          setIsSearchOpen(!isSearchOpen);
          document.body.style.overflow = "hidden";
        }
        setIsLocked(false);
        setIsOpenCatalog(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [headerCatalogRef, headerCatalogButtonRef]);
  const handleOpenCatalog = () => setCatalogVisible(true);
  const handleCloseCatalog = () => {
    setCatalogVisible(false);
  };
  return <>
      <div className={styles.bottomWrap}>
        {isSearchOpen && isMobile ? <button onClick={() => setIsSearchOpen(false)} className={styles.close_search} title="Назад">
            <ArrowSearch className={styles.close_icon} fill="#1D1D1D" />
          </button> : <HeaderMenu isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />}
        <HeaderLogo data-sentry-element="HeaderLogo" data-sentry-source-file="header-bottom.tsx" />
        <HeaderCatalogButton isOpen={catalogVisible} setIsOpen={handleOpenCatalog} ref={headerCatalogButtonRef} data-sentry-element="HeaderCatalogButton" data-sentry-source-file="header-bottom.tsx" />
        {!!catalog.length ? <NewCatalogModal catalog={catalog} visible={catalogVisible} handleClose={handleCloseCatalog} /> : null}
        <HeaderSearch isOpen={isSearchOpen} setIsOpen={setIsSearchOpen} ref={headerSearchRef} data-sentry-element="HeaderSearch" data-sentry-source-file="header-bottom.tsx" />
        <HeaderActions data-sentry-element="HeaderActions" data-sentry-source-file="header-bottom.tsx" />
      </div>
      <HeaderNav setIsOpenMenu={setIsOpenMenu} catalog={catalog} data-sentry-element="HeaderNav" data-sentry-source-file="header-bottom.tsx" />
    </>;
};