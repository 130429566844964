import HeaderActionsCart from "./header-actions-cart/header-actions-cart";
import { HeaderActionsChat } from "./header-actions-chat/header-actions-chat";
import HeaderActionsFavourite from "./header-actions-favourite/header-actions-favourite";
import HeaderActionsProfile from "./header-actions-profile/header-actions-profile";
import styles from "./header-actions.module.scss";
import { FC } from "react";
const HeaderActions: FC = () => {
  return <div className={styles.wrap} data-sentry-component="HeaderActions" data-sentry-source-file="header-actions.tsx">
      <HeaderActionsProfile data-sentry-element="HeaderActionsProfile" data-sentry-source-file="header-actions.tsx" />
      <HeaderActionsCart data-sentry-element="HeaderActionsCart" data-sentry-source-file="header-actions.tsx" />
      <HeaderActionsFavourite data-sentry-element="HeaderActionsFavourite" data-sentry-source-file="header-actions.tsx" />
      <HeaderActionsChat data-sentry-element="HeaderActionsChat" data-sentry-source-file="header-actions.tsx" />
    </div>;
};
export default HeaderActions;