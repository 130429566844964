import { CartIcon } from "@/components/icon";
import styles from "./header-actions-cart.module.scss";
import { FC, useEffect, useState } from "react";
import Link from "next/link";
import { useAppSelector } from "@/services/redux/hooks";
import { getUserInfo } from "@/services/redux/features/userSlice";
const HeaderActionsCart: FC = () => {
  const cart = useAppSelector(getUserInfo).info.cart;
  const countCart = cart.filter(item => !item.isRemoved).length;
  const [isClient, setIsClient] = useState(false);
  useEffect(() => setIsClient(true), []);
  return <div className={styles.wrap} data-sentry-component="HeaderActionsCart" data-sentry-source-file="header-actions-cart.tsx">
      <Link prefetch={false} className={styles.link} href="/cart/" title="Корзина" data-sentry-element="Link" data-sentry-source-file="header-actions-cart.tsx">
        <CartIcon data-sentry-element="CartIcon" data-sentry-source-file="header-actions-cart.tsx" />
        <span className={styles.count}>{isClient ? countCart : 0}</span>
      </Link>
    </div>;
};
export default HeaderActionsCart;