import Image from "next/image";
import styles from "./search-catalogs.module.scss";
import { FC } from "react";
import Link from "next/link";
import { UseMediaQuery } from "@/utils/functions/useMediaQuery";
import { ChevronIcon } from "@/components/icon";
interface ISearchCatalogs {
  catalogs: {
    [key: string]: string | number;
    sort: number;
  }[];
  closeModal: () => void;
}
export const SearchCatalogs: FC<ISearchCatalogs> = props => {
  const {
    catalogs,
    closeModal
  } = props;
  const sortedCatalogs = catalogs.sort((a, b) => a.sort - b.sort);
  const isMobile = UseMediaQuery("(max-width: 767px)");
  return <div className={styles.wrap} data-sentry-component="SearchCatalogs" data-sentry-source-file="search-catalogs.tsx">
      {sortedCatalogs.map((catalog, index: number) => {
      if (index > (isMobile ? 3 : 2)) return;
      return <Link prefetch={false} href={`${catalog.link}`} className={styles.item} key={index + "SearchCatalogs" + catalog.name} onClick={closeModal}>
            <div className={styles.info}>
              {catalog.picture && <Image className={styles.image} width={58} height={32} src={`https://ohotaktiv.ru${catalog.picture}`} alt={`${catalog.name}`} />}
              <p className={styles.name}>{catalog.name}</p>
            </div>
            <ChevronIcon className={styles.chevron} />
          </Link>;
    })}
    </div>;
};