"use client";

import styles from "./header-actions-profile.module.scss";
import { PersonalIcon } from "@/components/icon/personal-icon/personal-icon";
import { AuthPopup } from "@/components/popups";
import { useOpenModal } from "@/services/hooks/useOpenModal";
import { checkUserAuth, getAuthStatus, getIsNewUser, getUserInfo } from "@/services/redux/features/userSlice";
import { useAppDispatch, useAppSelector } from "@/services/redux/hooks";
import { useRouter } from "next/navigation";
import { FC, useEffect, useState } from "react";
const HeaderActionsProfile: FC = () => {
  const router = useRouter();
  const [showModal, handleOpenModal, handleCloseModal] = useOpenModal();
  const isAuthUser: boolean = useAppSelector(getAuthStatus).isAuth;
  const userName: string = useAppSelector(getUserInfo).info.name;
  const handleClick = () => isAuthUser ? router.push("/cabinet/") : handleOpenModal();
  const [isClient, setIsClient] = useState<boolean>(false);
  const isNewUser = useAppSelector(getIsNewUser);
  useEffect(() => setIsClient(true), []);
  const displayText = isClient ? isAuthUser ? userName ? userName : "Профиль" : "Войти" : "Войти";
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(checkUserAuth());
  }, []);
  return <>
      {showModal && <AuthPopup isOpen={showModal} onClose={handleCloseModal} />}
      {/* {isNewUser && (
        <WelcomeBonusPopup />
       )} */}
      <button onClick={handleClick} className={styles.button} title="Личный кабинет">
        <>
          <PersonalIcon data-sentry-element="PersonalIcon" data-sentry-source-file="header-actions-profile.tsx" />
          {displayText}
        </>
      </button>
    </>;
};
export default HeaderActionsProfile;