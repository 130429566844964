import { CloseIcon, RepeatIcon } from "@/components/icon";
import styles from "./search-history.module.scss";
import { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/services/redux/hooks";
import { getSearchList, removeItemSearch } from "@/services/redux/features/searchSlice";
interface ISearchHistory {
  onClickPopular: (value: string) => void;
}
export const SearchHistory: FC<ISearchHistory> = ({
  onClickPopular
}) => {
  const searchList = useAppSelector(getSearchList);
  const dispatch = useAppDispatch();
  const handleCloseClick = value => dispatch(removeItemSearch(value));
  return searchList.length > 0 && <div className={styles.wrap}>
        <p className={styles.title}>История</p>
        <div className={styles.buttonList}>
          {searchList.map((search, index: number) => {
        return <div key={`${index + "SearchHistory" + "searchList"}`} className={styles.buttonWrap}>
                <button onClick={() => onClickPopular(search)} className={styles.button}>
                  <RepeatIcon />
                  <p className={styles.buttonText}>
                    {search.replace(/[+]/g, " ")}
                  </p>
                </button>
                <button className={styles.buttonClose} onClick={() => handleCloseClick(search)}>
                  <CloseIcon className={styles.icon} />
                </button>
              </div>;
      })}
        </div>
      </div>;
};