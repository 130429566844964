// "use client";
// import { createPortal } from "react-dom";
// import styles from "./search-modal.module.scss";
// import { FC, forwardRef, Ref, useContext, useEffect, useRef, useState } from "react";
// import { SearchHistory } from "./search-history/search-history";
// import { SearchPopular } from "./search-popular/search-popular";
// import { SearchCardsList } from "./search-cards-list/search-cards-list";
// import { SearchContext } from "@/services/contexts/searchContext";
// import { ICardMini } from "@/services/types/types";
// import { Preloader } from "@/components/loading/preloader";

// interface ISearchModal {
//   items?: Array<ICardMini>;
//   query: string;
//   searchKeys?: string[];
//   onClickPopular: (value: string) => void;
//   closeSearch?: (arg?: boolean) => void;
//   isOpen?: boolean;
//   searchRedirect?: string;
// }

// const SearchModal: FC<ISearchModal> = forwardRef((props, ref: Ref<HTMLDivElement>) => {
//   const {
//     items,
//     query,
//     searchKeys,
//     onClickPopular,
//     closeSearch,
//     isOpen,
//     searchRedirect,
//   } = props;
//   const modalRoot = document.getElementById("modals");
//   const headerRef = useRef(document.getElementsByTagName("header")[0]);
//   const marqueeRef = useRef(document.getElementById("custom-marquee"));
//   const { startedItems, keys } = useContext(SearchContext) as any;
//   const closeModal = () => {
//     if (closeSearch) {
//       closeSearch(!isOpen);
//     }
//     if (!isOpen) {
//       document.body.style.overflow = "auto";
//     }
//   };
//   return createPortal(
//     <div
//       id="search-wrap"
//       className={styles.wrap}
//       style={{
//         // top: headerRef.current.offsetHeight + marqueeRef.current.offsetHeight,

//         top: headerRef.current.offsetHeight,
//       }}
//     >
//       <div
//         className={styles.overlay}
//         style={{
//           // top: headerRef.current.offsetHeight + marqueeRef.current.offsetHeight,
//           top: headerRef.current.offsetHeight,
//         }}
//       >
//         <div className={styles.container}>
//           <div className={styles.contentWrap} ref={ref}>
//             {startedItems.length > 0 ? (
//               <>
//                 <div
//                   className={`${styles.left} ${items &&
//                     items.length > 4 &&
//                     query.length > 0 &&
//                     styles.left_hidden
//                     }`}
//                 >
//                   <SearchHistory onClickPopular={onClickPopular} />
//                   <SearchPopular
//                     keys={searchKeys && !!searchKeys.length ? searchKeys : keys}
//                     onClickPopular={onClickPopular}
//                   />
//                   <SearchCardsList
//                     items={query.length > 0 ? items : startedItems}
//                     showButton={items && items.length > 4 && query.length > 0}
//                     query={query}
//                     onClick={closeModal}
//                     cardMiniDisable={isOpen}
//                     searchRedirect={searchRedirect}
//                   />
//                 </div>
//                 {/* <div className={styles.right}>
//                   <SearchCardsList
//                     items={query.length > 0 ? items : startedItems}
//                     showButton={items && items.length > 4 && query.length > 0}
//                     query={query}
//                     onClick={closeModal}
//                     cardMiniDisable={isOpen}
//                     searchRedirect={searchRedirect}
//                   />
//                 </div> */}
//               </>
//             ) : (
//               <div className={styles.preloaderWrap}>
//                 <Preloader />
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>,
//     modalRoot!
//   );
// });
// SearchModal.displayName = "SearchModal";
// export default SearchModal;

"use client";

import styles from "./search-modal.module.scss";
import { FC, forwardRef, Ref, useContext } from "react";
import { SearchHistory } from "./search-history/search-history";
import { SearchPopular } from "./search-popular/search-popular";
import { SearchCardsList } from "./search-cards-list/search-cards-list";
import { SearchContext } from "@/services/contexts/searchContext";
import { ICardMini } from "@/services/types/types";
import { Preloader } from "@/components/loading/preloader";
import { SearchCatalogs } from "./search-catalogs/search-catalogs";
import { SearchModalSkeleton } from "../search-modal-skeleton/search-modal-skeleton";
interface ISearchModal {
  items?: Array<ICardMini>;
  query: string;
  searchKeys?: string[];
  onClickPopular: (value: string) => void;
  closeSearch?: (arg?: boolean) => void;
  isOpen?: boolean;
  searchRedirect?: string;
  searchCatalogs: {
    [key: string]: string | number;
    sort: number;
  }[];
  isFetching: boolean;
}
const SearchModal: FC<ISearchModal> = forwardRef((props, ref: Ref<HTMLDivElement>) => {
  let {
    items = [],
    query,
    searchKeys,
    onClickPopular,
    closeSearch,
    isOpen,
    searchRedirect,
    searchCatalogs,
    isFetching
  } = props;
  // const modalRoot = document.getElementById("modals");
  // const headerRef = useRef(document.getElementsByTagName("header")[0]);
  // const marqueeRef = useRef(document.getElementById("custom-marquee"));
  const {
    startedItems,
    keys,
    catalogs,
    contextLoading
  } = useContext(SearchContext) as any;
  const closeModal = () => {
    if (closeSearch) {
      closeSearch(!isOpen);
    }
    if (!isOpen) {
      document.body.style.overflow = "auto";
    }
  };
  const itemsToShow = !!query.length ? items.length ? items : [] : startedItems;
  return <div id="search-wrap" className={styles.wrap} ref={ref}>
        <div className={styles.container}>
          {isFetching && query || contextLoading ? <SearchModalSkeleton /> : <>
              {!query.length && <div className={styles.head}>
                  <SearchHistory onClickPopular={onClickPopular} />
                  <SearchPopular keys={searchKeys && !!searchKeys.length ? searchKeys : keys} onClickPopular={onClickPopular} />
                </div>}
              <SearchCatalogs closeModal={closeModal} catalogs={searchCatalogs && query ? searchCatalogs : catalogs} />
              <SearchCardsList items={itemsToShow} showButton={items && items.length > 4 && query.length > 0} query={query} onClick={closeModal} cardMiniDisable={isOpen} searchRedirect={searchRedirect} />
            </>}
        </div>
      </div>;
});
SearchModal.displayName = "SearchModal";
export default SearchModal;
{
  /* <div
   className={styles.overlay}
   style={{
   // top: headerRef.current.offsetHeight + marqueeRef.current.offsetHeight,
   top: headerRef.current.offsetHeight,
   }}
   >
   <div className={styles.container}>
   <div className={styles.contentWrap} ref={ref}>
   {startedItems.length > 0 ? (
   <>
   <div
   className={`${styles.left} ${items &&
   items.length > 4 &&
   query.length > 0 &&
   styles.left_hidden
   }`}
   >
   <SearchHistory onClickPopular={onClickPopular} />
   <SearchPopular
   keys={searchKeys && !!searchKeys.length ? searchKeys : keys}
   onClickPopular={onClickPopular}
   />
   <SearchCardsList
   items={query.length > 0 ? items : startedItems}
   showButton={items && items.length > 4 && query.length > 0}
   query={query}
   onClick={closeModal}
   cardMiniDisable={isOpen}
   searchRedirect={searchRedirect}
   />
   </div>
   <div className={styles.right}>
   <SearchCardsList
   items={query.length > 0 ? items : startedItems}
   showButton={items && items.length > 4 && query.length > 0}
   query={query}
   onClick={closeModal}
   cardMiniDisable={isOpen}
   searchRedirect={searchRedirect}
   />
   </div>
   </>
   ) : (
   <div className={styles.preloaderWrap}>
   <Preloader />
   </div>
   )}
   </div>
   </div>
   </div> */
}