import { GeoIcon } from "@/components/icon";
import styles from "./geo-menu-button.module.scss";
import { FC } from "react";
import { useOpenModal } from "@/services/hooks/useOpenModal";
import { GeoPopup } from "@/components/popups";
import { useAppSelector } from "@/services/redux/hooks";
import { getCity } from "@/services/redux/features/citySlice";
interface IGeoMenu {}
export const GeoMenuButton: FC<IGeoMenu> = () => {
  const [showModal, handleOpenModal, handleCloseModal] = useOpenModal();
  const city = useAppSelector(getCity).city;
  return <>
      {showModal && <GeoPopup isOpen={showModal} onClose={handleCloseModal} />}
      <button onClick={handleOpenModal} className={styles.button}>
        <GeoIcon data-sentry-element="GeoIcon" data-sentry-source-file="geo-menu-button.tsx" />
        {city}
      </button>
    </>;
};