"use client";

import { StatusOrderPopup } from "@/components/popups";
import styles from "./header-status.module.scss";
import { FC } from "react";
import { useOpenModal } from "@/services/hooks/useOpenModal";
const HeaderStatus: FC = () => {
  const [showModal, handleOpenModal, handleCloseModal] = useOpenModal();
  return <>
      {showModal && <StatusOrderPopup isOpen={showModal} onClose={handleCloseModal} />}
      <button onClick={handleOpenModal} className={styles.button}>
        Статус заказа
      </button>
    </>;
};
export default HeaderStatus;