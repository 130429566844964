"use client";

import React, { useEffect, useState } from 'react';
interface LazySvgProps extends React.ComponentProps<'svg'> {
  name: string;
}
export const Icon = ({
  name,
  ...props
}: LazySvgProps) => {
  const [IconComponent, setIconComponent] = useState<React.ComponentType | null>(null);
  useEffect(() => {
    import(`@/public/images/icons/${name}.svg`).then(mod => {
      setIconComponent(() => mod.default);
    }).catch(error => {
      console.error(`Error loading SVG: ${name}`, error);
    });
  }, [name]);
  if (!IconComponent) {
    return null;
  }
  return <IconComponent {...props} data-sentry-element="IconComponent" data-sentry-component="Icon" data-sentry-source-file="index.tsx" />;
};